import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout.js"
import SEO from "../components/seo-component.js" 
import GridIcons from "../components/content/gridicons" 

const IndexPage = ({ siteTitle, data }) => (
  <StaticQuery
    query={graphql`
      query InnerIndexPageQuery {
        site {
          siteMetadata {
            title
            url
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <>
        <Layout>
    
          <SEO title={data.site.siteMetadata.description} />

          <div className={"page-header home"}>
            <h1>{data.site.siteMetadata.description}</h1>
            {/* <p>DataPolicyTrust gives your complex the opportunity to increase the percentage of happiness<br/>and enjoyment of your staff and as a result, bring productivity to your workspace.</p> */}
            <br/>
            <p>{data.site.siteMetadata.homeDescription}</p>
            {/* <img alt={"Dashboard"} src={featureImage}/> */}
            <br/>
            {/* <img data-src="holder.js/939x665?theme=gray"/> */}
            {/* <div className="col-xs-auto">
                            <img src="/images/twitterscreenshot1.png" className="center-block  img-responsive mx-auto" />
                          </div> */}
        
          </div>

          <section className="features features-3">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-md-offset-1b col-sm-12 text-center">
                  <h3>
                    Monitoring privacy, policies, risks &amp; trust of companies.{" "}
                  </h3>
                  {/* <p>
                    
                  </p> */}
                </div>
              </div>

              

          <GridIcons/>
          <div className="row">
          <div className="col-md-12 text-center pb-4"><br/><br/>
          <Link to="/howitworks" className="btn btn-primary  btn-sm">Learn More about How DataPolicyTrust Works?</Link>
          <br/><br/>  </div>

        </div>
            </div>
          </section>
{/* 
          <div className={"container d-none"}>
            <div className={"features"}>
              <div className={"feature__item"}>
                <div className="row">
                  <div className={"col-6 first"}>
                    <div className={"thumbnail"}> 
                      <img
                        className="featured"
                        data-src="holder.js/442x332?theme=gray"
                      />
                    </div>
                  </div>

                  <div className={"col-6"}>
                    <div className={"feature__content"}>
                      <h2>DPT is a free public service</h2>
                      <p>
                        We help monitor the safety of the Internet when it comes
                        to our shared data privacy. DPT is a free service for
                        everyone with our AI-powered watchdog, guardian and
                        sentinel to watch over the Internet and all good and bad
                        actors who try to cause issues for people.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={"feature__item"}>
                <div className="row">
                  <div className={"col-6"}>
                    <div className={"feature__content"}>
                      <h2>You can Trust DPT!</h2>
                      <p>
                        Unlike the past, with DPT, you don’t have to worry about
                        us being creepy with your data as we don’t share your
                        PII and browsing data like some others have been known
                        to do.
                      </p>
                    </div>
                  </div>

                  <div className={"col-6 first"}>
                    <div className={"thumbnail"}> 
                      <img
                        className="featured"
                        data-src="holder.js/442x332?theme=gray"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={"feature__item"}>
                <div className="row">
                  <div className={"col-6 first"}>
                    <div className={"thumbnail"}> 
                      <img
                        className="featured"
                        data-src="holder.js/442x332?theme=gray"
                      />
                    </div>
                  </div>

                  <div className={"col-6"}>
                    <div className={"feature__content"}>
                      <h2>DPT is a free public service for people</h2>
                      <p>
                        We help monitor the safety of the Internet when it comes
                        to our shared data privacy. DPT is a free service for
                        everyone with our AI-powered watchdog, guardian and
                        sentinel to watch over the Internet and all good and bad
                        actors who try to cause issues for people.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={"feature__item"}>
                <div className="row">
                  <div className={"col-6"}>
                    <div className={"feature__content"}>
                      <h2>We are better!</h2>
                      <p>
                        We didn't think what was out there really was helping
                        people. Unlike other solutions, with DPT, you don’t have
                        to worry about subjective ratings, opinions or data that
                        may be stale as we provide true monitoring & analytics
                        as well as intelligence and reporting instead of just
                        ‘review’ tools.
                      </p>
                    </div>
                  </div>

                  <div className={"col-6 first"}>
                    <div className={"thumbnail"}> 
                      <img
                        className="featured"
                        data-src="holder.js/442x332?theme=gray"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={"feature__item"}>
                <div className="row">
                  <div className={"col-6 first"}>
                    <div className={"thumbnail"}> 
                      <img
                        className="featured"
                        data-src="holder.js/442x332?theme=gray"
                      />
                    </div>
                  </div>

                  <div className={"col-6"}>
                    <div className={"feature__content"}>
                      <h2>
                        Simple & transparent data protection insights on the
                        Internet
                      </h2>
                      <p>
                        Discard traditional ways to review privacy, security and
                        other policy information about apps, products, services
                        and sites, and try new ways to review and monitor them
                        in DataPolicyTrust easily.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className={"call-to-action"}>
            <div className="container">
              <div className={"call-to-action__content"}>
                <h2>Sign up for free</h2>
                <p>
                  Sign up to start improving your digital world with {data.site.siteMetadata.title}.
                </p>
              </div>

              <div className={"button"}>
                {/* <a href={data.site.siteMetadata.getStartedUrl} 
                target={"_blank"}
                >
                  Sign Up
                </a> */}
                <Link to={data.site.siteMetadata.getStartedUrl} 
                // target={"_blank"}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )}
  />
)

IndexPage.propTypes = {
  siteTitle: PropTypes.string,
}

IndexPage.defaultProps = {
  siteTitle: ``,
}

export default IndexPage
